<template>
  <div class="quill-editor">
    <slot name="toolbar" />
    <div ref="editor" />
    <component :is="`style`">
      .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      content: '{{ $t('Guardar') }}'!important;
      }

      .ql-snow .ql-tooltip a.ql-action::after {
      content: '{{ $t('Editar') }}'!important;
      }

      .ql-snow .ql-tooltip a.ql-remove::before{
      content: '{{ $t('Eliminar') }}'!important;
      }

      .ql-snow .ql-tooltip[data-mode=link]::before {
      content: '{{ $t('Inserir link') }}:'!important;
      }

      .ql-snow .ql-tooltip::before{
      content: '{{ $t('Link') }}:'!important;
      }
    </component>
  </div>
</template>

<script>
import _Quill from 'quill'
import defaultOptions from './options'

const Quill = window.Quill || _Quill

// Para subsituir os enters "p" para "div"
const Block = Quill.import('blots/block')
Block.tagName = 'div'
Quill.register(Block)

// export
export default {
  name: 'QuillEditor',
  props: {
    content: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionsDefault: {},
      contentDefault: '',
      defaultOptions,
    }
  },
  watch: {
    // Watch content change
    content(newVal) {
      if (this.quill) {
        if (newVal && newVal !== this.contentDefault) {
          this.contentDefault = newVal
          this.quill.pasteHTML(newVal)
        } else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    // Watch content change
    value(newVal) {
      if (this.quill) {
        if (newVal && newVal !== this.contentDefault) {
          this.contentDefault = newVal
          this.quill.pasteHTML(newVal)
        } else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    // Watch disabled change
    disabled(newVal) {
      if (this.quill) {
        this.quill.enable(!newVal)
      }
    },
  },
  mounted() {
    this.initialize()
  },
  beforeDestroy() {
    this.quill = null
    delete this.quill
  },
  methods: {
    // Init Quill instance
    initialize() {
      if (this.$el) {
        // Options
        this.optionsDefault = { ...this.defaultOptions, ...this.globalOptions, ...this.options }

        // Instance
        this.quill = new Quill(this.$refs.editor, this.optionsDefault)

        this.quill.enable(false)

        // Set editor content
        if (this.value || this.content) {
          this.quill.pasteHTML(this.value || this.content)
        }

        // Disabled editor
        if (!this.disabled) {
          this.quill.enable(true)
        }

        // Mark model as touched if editor lost focus
        this.quill.on('selection-change', range => {
          if (!range) {
            this.$emit('blur', this.quill)
          } else {
            this.$emit('focus', this.quill)
          }
        })

        // Update model if text changes
        this.quill.on('text-change', () => {
          let html = this.$refs.editor.children[0].innerHTML
          const { quill } = this
          const text = this.quill.getText()

          if (text.trim() === '') {
            html = ''

            setTimeout(() => {
              this.$refs.editor.children[0].innerHTML = ''
            }, 1)
          }

          this.contentDefault = html
          this.$emit('input', this.contentDefault)
          this.$emit('change', { html, text, quill })
        })

        // Emit ready event
        this.$emit('ready', this.quill)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
